<!-- 变压器温控 -->
<template>
  <div id="transformer">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>变压器</span>
      </div>
      <ul class="tabs">
          <li v-for="item in typeList" :key="item.type" :class="typeLabel == item.value ? 'select' : ''"
            @click="switchTabs(item)">
            {{ item.name }}
          </li>
        </ul>
      <div class="content">
        <el-table class="table" :data="tableData">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="设备名称" prop="name" v-if="typeLabel==2">
          </el-table-column>
          <el-table-column align="center" label="设备名称" prop="deviceName" v-else>
          </el-table-column>
          <el-table-column align="center" label="设备编码" prop="deviceNum">
          </el-table-column>
          <el-table-column align="center" label="告警级别" prop="levelName" v-if="typeLabel==2">
          </el-table-column>
          <el-table-column align="center" label="参数" prop="real" v-if="typeLabel==1">
          </el-table-column>
          <el-table-column align="center" label="参数" prop="temp" v-if="typeLabel==2">
          </el-table-column>
          <el-table-column align="center" label="限定值" prop="limiting" >
          </el-table-column>
          <el-table-column align="center" label="时间" prop="createTime">
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { securityControlApi } from "@/api/securityControl.js";
import { type } from "jquery";
export default {
  name: "",

  data() {
    return {
      tableData: [],
      requestParam: {
        id: "",
        pageSize:10,
        pageNum:1,
      },
      inputValue:1,
      total:0,
      typeList:[
        {
          name:'噪声',
          value:1,
        },
        {
          name:'温控',
          value:2,
        },
      ],
      typeLabel:1,
    };
  },
  created() {
    this.$store.commit("increment", "安全用电");
    this.$store.commit("selectChild", "用电安全");
    this.$store.commit("selectChildren", "变压器");
    this.requestParam.id= sessionStorage.getItem("stationId")
  },
  mounted() {
    this.queryElectricalSafety();
  },

  methods: {
    switchTabs(item) {
      // this.selectName=item.name
      this.typeLabel = item.value;
      this.queryElectricalSafety()
    },
    queryElectricalSafety() {
      let obj={
        id:this.requestParam.id,
        type: this.typeLabel,
        pageNum:this.requestParam.pageNum,
        pageSize:this.requestParam.pageSize,
      }
      securityControlApi.controlList(obj).then((res) => {
        if (res.code === 200) {
          this.tableData =res.data
          this.total = res.total
          // this.tableData =this.tableData.concat(
          //   res.data.zhaosheng,
          //   res.data.wenshi,
          // );
        }
      });
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryElectricalSafety();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryElectricalSafety();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryElectricalSafety();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryElectricalSafety();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryElectricalSafety();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryElectricalSafety();
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#transformer {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}
.main {
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
}
.table{
  margin-bottom: 16px;
}
/* 滚动条样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
 
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  width: 24px;
  height: 40px;
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
::v-deep .el-table__body-wrapper {
  max-height: 75vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
.tabs {
  /* width: 1336px; */
  background-color: rgb(4, 25, 66);
  display: flex;
  margin-bottom: 10px;
  padding: 10px 0;
}

.tabs li {
  width: 122px;
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.tabs li:hover{
  background-color: #002c6c;
  color: #ffc74a;
}
.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>

